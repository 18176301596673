.scrollDownContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 2;
  width: 25px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.scrollDownContainer > a {
  border-radius: 20px;
  border: 1px solid #ffffff;
  padding: 0 5px;
  display: block;
  width: 100%;
  text-align: center;
}

@keyframes scroll-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.scrollDownContainer > a > span {
  width: 6px;
  height: 12px;
  background: #ffffff;
  display: inline-block;
  border-radius: 5px;
  animation: scroll-down 2s ease-in-out infinite;
}
