.homeSectionContainer {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background: url('../../../assets/images/innovate.jpg') no-repeat center center/cover;
}
