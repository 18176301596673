.gradientOverlayContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0.85;
  position: absolute;
  background-image: linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%);
}