@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.ParticlesWrapper_particlesWrapperContainer__2XJWm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}



.Company_companyContainer__dSZL2 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  margin-top: 20px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.Company_companyContainer__dSZL2 .Company_name__2hS_Z,
.Company_companyContainer__dSZL2 .Company_services__3GIyo {
  font-size: 2rem;
  letter-spacing: 1px;
}

.Company_companyContainer__dSZL2 .Company_name__2hS_Z {
  font-weight: 400;
}

.Company_companyContainer__dSZL2 .Company_services__3GIyo {
  font-weight: 200;
}

@media screen and (min-width: 768px) {
  .Company_companyContainer__dSZL2 .Company_name__2hS_Z,
  .Company_companyContainer__dSZL2 .Company_services__3GIyo {
    font-size: 5rem;
    letter-spacing: 1px;
  }
}
.ScrollDown_scrollDownContainer__1gbwg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 2;
  width: 25px;
  height: 40px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.ScrollDown_scrollDownContainer__1gbwg > a {
  border-radius: 20px;
  border: 1px solid #ffffff;
  padding: 0 5px;
  display: block;
  width: 100%;
  text-align: center;
}

@-webkit-keyframes ScrollDown_scroll-down__3sked {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes ScrollDown_scroll-down__3sked {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.ScrollDown_scrollDownContainer__1gbwg > a > span {
  width: 6px;
  height: 12px;
  background: #ffffff;
  display: inline-block;
  border-radius: 5px;
  -webkit-animation: ScrollDown_scroll-down__3sked 2s ease-in-out infinite;
          animation: ScrollDown_scroll-down__3sked 2s ease-in-out infinite;
}

.GradientOverlay_gradientOverlayContainer__3KIMr {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0.85;
  position: absolute;
  background-image: linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%);
}
.HomeSection_homeSectionContainer__q_i_p {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  overflow: hidden;
  background: url(/static/media/innovate.2097ed89.jpg) no-repeat center center/cover;
}

.AboutUsSection_aboutUsSectionContainer__2FFtb {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.BlogSection_blogSectionContainer__1D_7- {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.CoFoundersSection_coFoundersSectionContainer__3rzHa {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.OurServicesSection_ourServicesSectionContainer__-jEru {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.ContactSection_contactSectionContainer__2khi9 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.Home_homeContainer__1eWAk {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Blog_blogContainer__1yAfH {

}

.Footer_footerContainer__270mZ {
  width: 100%;
  min-height: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #2E303C;
}

.Footer_footerContainer__270mZ > .Footer_copyright__3AAlV {
  font-weight: bold;
  color: #ffffff;
}

.Header_headerContainer__1RWrR {

}

.Layout_layoutContainer__1k-e5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.UnderConstruction_underConstructionContainer__2seuq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100vw;
}

.UnderConstruction_underConstructionContainer__2seuq > img.UnderConstruction_underConstruction__NYQI3 {
  height: 75%;
  width: 75%;
}
