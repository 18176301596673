.underConstructionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.underConstructionContainer > img.underConstruction {
  height: 75%;
  width: 75%;
}