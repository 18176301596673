.footerContainer {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #2E303C;
}

.footerContainer > .copyright {
  font-weight: bold;
  color: #ffffff;
}
