@value breakpoints: '../../../../styles/variables/breakpoints.css';
@value md from breakpoints;

.companyContainer {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  margin-top: 20px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.companyContainer .name,
.companyContainer .services {
  font-size: 2rem;
  letter-spacing: 1px;
}

.companyContainer .name {
  font-weight: 400;
}

.companyContainer .services {
  font-weight: 200;
}

@media screen and (min-width: md) {
  .companyContainer .name,
  .companyContainer .services {
    font-size: 5rem;
    letter-spacing: 1px;
  }
}